<!--
File: SectionsDetails.vue
Description: show details of a selected road section as a dialog window.
-->
<template>
  <md-dialog :md-active.sync="this.section !== null" :md-click-outside-to-close="false">
    <md-dialog-title>{{ $t('road_network.section_details') }}
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='onModalClose'>
        <md-icon>clear</md-icon>
      </md-button>
    </md-dialog-title>
    <md-dialog-content>
      <md-table class="table-striped">
        <md-table-row>
          <md-table-cell> {{ $t('inventory.carriageway') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.carriageway }} </md-table-cell>
          <md-table-cell> &nbsp; </md-table-cell>
          <md-table-cell> {{ $t('label.category') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.category }} </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell> {{ $t('road_network.pic_id') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.pic_id }} </md-table-cell>
          <md-table-cell> &nbsp; </md-table-cell>
          <md-table-cell> {{ $t('inventory.fclass') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.fclass }} </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell> {{ $t('inventory.villages') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.villages }} </md-table-cell>
          <md-table-cell> &nbsp; </md-table-cell>
          <md-table-cell> {{ $t('inventory.aadt') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.aadt }} </md-table-cell>
        </md-table-row>
        <md-table-row>
          <md-table-cell> {{ $t('inventory.social_importance') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.social_importance }} </md-table-cell>
          <md-table-cell> &nbsp; </md-table-cell>
          <md-table-cell> {{ $t('condition.black_spots') }}: </md-table-cell>
          <md-table-cell md-numeric> {{ section.black_spots }} </md-table-cell>
        </md-table-row>
      </md-table>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="onModalClose">{{ $t('buttons.close') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { onModalClose } from '@/mixins/onCloseMixin'

export default {
  name: 'section-details-form',
  props: {
    sectionId: null,
  },
  data() {
    return {
      section: {
        carriageway: null,
        category: null,
        pic_id: null,
        fclass: null,
        villages: null,
        aadt: null,
        social_importance: null,
        black_spots: null,
      }
    }
  },
  mounted() {
    if (!this.sectionId) return

    this.$store.dispatch('LOAD_SECTION_BY_ID', this.sectionId).then((res) => {
      this.section = Array.isArray(res) ? res[0] : res
    })
    //this.$store.dispatch('LOAD_SECTION_GEOMETRY_ALL', this.sectionId)
  },
  methods: {
    onModalClose,   // Подключаем метод onModalClose в методы компонента
  },
  computed: {
    ...mapState({
      sectionGeometry: (state) => state.SectionGeometry.list,
    }),
    ...mapGetters(['lastGeometry']),
  }
}
</script>
